import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useContext } from "react";
import Questions from "./questions";
import SolContent from "../utils/solContent";
import SolHero from "./solHero";
import { ThemeContext } from "../pages/rootLayout";
import Header from "../utils/header";
import Card from "../utils/card";
import Gradient from "../utils/gradient";
import { icons } from "../utils/icons";

const Solutions = () => {
  const themeContext = useContext(ThemeContext);
  return (
    <Box>
      <SolHero />
        <SolContent
        align="start"
        lgdir="row-reverse"
        head="مميزات التطبيق"
        title=" مميزات التطبيق"
        img={`app-builder${themeContext === "dark" ? "-dark" : ""}.png`}
        padding="3.5rem 0  0 0"
        text={
          <>
            <Stack direction="row" sx={{ alignItems: "start", gap: "1rem" ,marginBottom:"0.8rem" ,textAlign:"right" }}>
              <Box component="span">{icons.smallStar}</Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "500", color: "var(--gray)" ,lineHeight:"1.8"}}
              >
                تفعيل التوصيل والتحكم بأقصى عدد كيلو مترات يمكن التوصيل له
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ alignItems: "start", gap: "1rem" ,marginBottom:"0.8rem" ,textAlign:"right"}}>
              <Box component="span">{icons.smallStar}</Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "500", color: "var(--gray)" ,lineHeight:"1.8"}}
              >
                التحكم بوقت استلام الطلبات
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ alignItems: "start", gap: "1rem" ,marginBottom:"0.8rem" ,textAlign:"right"}}>
              <Box component="span">{icons.smallStar}</Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "500", color: "var(--gray)" ,lineHeight:"1.8"}}
              >
                نظام نقاط الولاء لعملائك
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ alignItems: "start", gap: "1rem" ,marginBottom:"0.8rem" ,textAlign:"right"}}>
              <Box component="span">{icons.smallStar}</Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "500", color: "var(--gray)" ,lineHeight:"1.8"}}
              >
                التحكم بنقاط الولاء ، التوزيع لكل ريال
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ alignItems: "start", gap: "1rem" ,marginBottom:"0.8rem" ,textAlign:"right"}}>
              <Box component="span">{icons.smallStar}</Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "500", color: "var(--gray)" ,lineHeight:"1.8"}}
              >
                اضافة فروع
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ alignItems: "start", gap: "1rem" ,marginBottom:"0.8rem" ,textAlign:"right"}}>
              <Box component="span">{icons.smallStar}</Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "500", color: "var(--gray)" ,lineHeight:"1.8"}}
              >
                ربطه مع اجهزة كاشير ويتر في حال توفرها، وربط الاحصائيات
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ alignItems: "start", gap: "1rem" ,marginBottom:"0.8rem" ,textAlign:"right"}}>
              <Box component="span">{icons.smallStar}</Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "500", color: "var(--gray)" ,lineHeight:"1.8"}}
              >
                ادارة طرق الدفع، تفعيل والغاء ابل باي، فيزا، مدى
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ alignItems: "start", gap: "1rem" ,marginBottom:"0.8rem" ,textAlign:"right"}}>
              <Box component="span">{icons.smallStar}</Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "500", color: "var(--gray)" ,lineHeight:"1.8"}}
              >
                فواتير الكترونيه لكل طلب
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ alignItems: "start", gap: "1rem" ,marginBottom:"0.8rem" ,textAlign:"right"}}>
              <Box component="span">{icons.smallStar}</Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "500", color: "var(--gray)" ,lineHeight:"1.8"}}
              >
                تطبيق على ابل ستور وقوقل بلاي
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ alignItems: "start", gap: "1rem" ,marginBottom:"0.8rem" ,textAlign:"right"}}>
              <Box component="span">{icons.smallStar}</Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "500", color: "var(--gray)" ,lineHeight:"1.8"}}
              >
                اضافة منتجات لانهائيه
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ alignItems: "start", gap: "1rem" ,marginBottom:"0.8rem" ,textAlign:"right"}}>
              <Box component="span">{icons.smallStar}</Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "500", color: "var(--gray)" ,lineHeight:"1.8"}}
              >
تطبيق الايفون مُبرمج على لغة سويفت وتطبيق الاندرويد على فلاتر
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ alignItems: "start", gap: "1rem" ,marginBottom:"0.8rem" ,textAlign:"right"}}>
              <Box component="span">{icons.smallStar}</Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "500", color: "var(--gray)" ,lineHeight:"1.8"}}
              >
ماهي سويفت وماهي فلاتر؟ سويفت هي اللغه الخاصه بنظام ابل وعندما تبرمج بها تطبيق لأجهزة ابل يصبح اكثر سلاسه ويعطي تجربه واداء مميز للمستخدم فهي اللغه المطوره للهاتف كذلك الاندرويد،
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ alignItems: "start", gap: "1rem" ,marginBottom:"0.8rem" ,textAlign:"right"}}>
              <Box component="span">{icons.smallStar}</Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "500", color: "var(--gray)" ,lineHeight:"1.8"}}
              >
لأننا في ويتر نؤمن ان تجربة المستخدم مهمه واداء التطبيق مهم نستخدم اللغه الافضل لكل جهاز، عكس مايقدمه الآخرون يستخدمون لغه واحده لكل الاجهزه مما يعطي تجربه سيئه واعطال كثيره للتطبيق.              </Typography>
            </Stack>
          </>
        }
        width="200%"
        gap="5rem"
      />
      <SolContent
        align="start"
        lgdir="row"
        head="كاشير ويتر"
        title="الحلول"
        img={`tablet-lg${themeContext === "dark" ? "-dark" : ""}.png`}
        padding="3.5rem 0 0 0"
        text="اجهزة كاشير ويتر او نظام ويتر باشتراك شهري، نوفر لك الخيارين في حال ليس لديك اجهزة الكاشير يمكنك شراء جميع الاجهزه وفي حال لديك الاجهزه وترغب بالتحويل يمكنك الاشتراك .في باقه مخفضه 99 ريال شهريًا ( لابد من توفر ايباد من ابل )
الكاشير يدعم الفاتوره الالكترونيه، اضافة موظفين، التحكم بالمنتجات، التحكم بصلاحيات الموظفين، اضافة اكواد خصم، مميزات الكاشير تجدها في الاسفل 👇🏻."
        width="55%"
        gap="9.3rem"
      />
      <SolContent
        align="center"
        lgdir="column"
        head="ربط كاشير ويتر مع تطبيقك!"
        img={`wide-tablet-lg${themeContext === "dark" ? "-dark" : ""}.png`}
        padding="8rem 18rem "
        text="في حال لديك تطبيق من ويتر وكاشير من ويتر فالربط تلقائي والاحصائيات واحده، واذا لديك اكثر من فرع ستذهب الطلبات من التطبيق لكاشير الفرع المطلوب منه، عمليات منظمه لاتقلق!"
        width="65%"
        title="الحلول"
      />
      <SolContent
        align="start"
        lgdir="row"
        head="اضافة منتجات من تطبيق الكاشير"
        title="مميزات"
        img={`add-products${themeContext === "dark" ? "-dark" : ""}.png`}
        padding="0 3.5rem 0 0"
        text="اضافة منتجات من تطبيق الكاشير، بعد منح الموظف الصلاحيه."
        width="150%"
        gap="5rem"
      />
      <SolContent
        align="start"
        lgdir="row-reverse"
        head="التحكم بالطاولات"
        title="مميزات"
        img={`Invocies${themeContext === "dark" ? "-dark" : ""}.png`}
        padding="3.5rem 0  0 0"
        text=""
        width="175%"
        gap="5rem"
      />
      <SolContent
        align="center"
        lgdir="column"
        head="طباعة فواتير الطلبات اي وقت دون قيود."
        img={`print-invoice${themeContext === "dark" ? "-dark" : ""}.png`}
        padding="8rem 14rem "
        text="فواتير لانهائيه، على عكس مايقدمه الآخرون فنحن نقدم فواتير لانهائيه يمكنك الوصول لها اي وقت دون اشتراك!."
        width="65%"
        title="مميزات"
        gap="1rem"
      />
      <SolContent
        align="start"
        lgdir="row"
        head="اضافة اكثر من موظف للكاشير"
        title="مميزات"
        img={`multi-employes${themeContext === "dark" ? "-dark" : ""}.png`}
        padding="3.5rem 0  0 0"
        text="اضافة اكثر من موظف للكاشير وتحديد صلاحيات كل موظف."
        width="175%"
        gap="5rem"
      />

    

      <Box id="advantages">
        <Box className="container set-padding">
          <Header
            title={"بالإضافة إلى مميزات أخرى"}
            text="إكتشف المزيد من المميزات."
            right={true}
            left={false}
          />
          <Box sx={{ position: "relative", marginTop: "2.5rem" }}>
            <Gradient
              gtop="25%"
              gleft="35%"
              ytop="15%"
              yright="50%"
              opacity={1}
            />
            <Grid container justifyContent="center" spacing={2}>
              <Grid item lg={4} md={6} xs={12}>
                <Card
                  title={"اشتراك مدى الحياة"}
                  content={""}
                  icon={icons.Wallet}
                  index={0}
                  extraClasses={{height: "100%",alignItems:"center"}}
                />
              </Grid>
          
              <Grid item lg={4} md={6} xs={12}>
      
                <Card
                  title={"دعم اكثر من لغه"}
                  content={""}
                  icon={icons.wifi}
                  index={2}
                    extraClasses={{height: "100%",alignItems:"center"}}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <Card
                  title={"اضافة اكثر من فرع"}
                  content={
                  ""
                  }
                  icon={icons.location}
                  index={3}
                    extraClasses={{height: "100%",alignItems:"center"}}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {/* <Features theme={themeContext} /> */}
      <Questions />
    </Box>
  );
};

export default Solutions;
