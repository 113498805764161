import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Input from "../utils/input";
import Tel from "../utils/tel";

const Signup = () => {
  const { t } = useTranslation("auth");
  const [errorMsg, setErrorMsg] = useState();

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    if (isSubmitting) return;
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }

      const response = await fetch("https://api.waiter.sa/auth/seller/signup", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorObj = await response.json();
        throw new Error(errorObj.msg || "Request failed");
      }
      const result = await response.json();
      const url = `https://dashboard.waiter.sa/auth/otp-verifying?id=${result.seller.id}&phone_number=${result.seller.phone_number}`;
      window.location.href = "/thankyou?dash_url=" + url;
    } catch (error) {
      setErrorMsg(error.message || "An error occurred");
    }
  };

  return (
    <Box id="signup-questions">
      <div className="auth-base">
        <div className="auth-content">
          <div className="py-5 px-3">
            <div>
              <div className="text-center mb-4">
                <h1 className="singup-title-text">{t("register.title")}</h1>
              </div>
              <form id="signup-form" onSubmit={handleSubmit(onSubmit)}>
                {errorMsg && (
                  <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                    role="alert"
                  >
                    <span className="block sm:inline">{errorMsg}</span>
                  </div>
                )}
                <Input
                  id="username"
                  name="username"
                  type="text"
                  label={t("username")}
                  placeholder={t("username")}
                  isRequired={true}
                  register={register}
                  error={errors["username"]}
                  validationSchema={{
                    required: t("forms.field_required", {
                      field: t("username"),
                    }),
                  }}
                />

                <Input
                  id="email"
                  name="email"
                  type="email"
                  label={t("email")}
                  placeholder={t("email")}
                  isRequired={true}
                  register={register}
                  error={errors["email"]}
                  validationSchema={{
                    required: t("forms.field_required", {
                      field: t("email"),
                    }),
                  }}
                />

                <Input
                  id="password"
                  name="password"
                  type="password"
                  label={t("password")}
                  placeholder={t("password")}
                  isRequired={true}
                  register={register}
                  error={errors["password"]}
                  validationSchema={{
                    required: t("forms.field_required", {
                      field: t("password"),
                    }),
                    minLength: {
                      value: 8,
                      message: t("forms.field_minLength", {
                        field: t("password"),
                        min: 8,
                      }),
                    },
                    validate: {
                      pattern: (val) => {
                        if (!val.match(/[A-Z]/g)) {
                          return t(
                            "Password must contain at least one capitalized letter"
                          );
                        }
                        if (!val.match(/[0-9]/g)) {
                          return t("Password must contain at least one number");
                        }
                        if (!val.match(/[#?!@$%^&*-|\[\]\{\}]/g)) {
                          return t("Password must contain special characters");
                        }
                      },
                    },
                  }}
                />

                <Input
                  id="password_confirmation"
                  name="password_confirmation"
                  type="password"
                  label={t("password_confirmation")}
                  placeholder={t("password_confirmation")}
                  isRequired={true}
                  register={register}
                  error={errors["password_confirmation"]}
                  validationSchema={{
                    required: t("forms.field_required", {
                      field: t("password_confirmation"),
                    }),
                    validate: {
                      match: (val) => {
                        if (watch("password") != val) {
                          return t("Your passwords do no match");
                        }
                      },
                    },
                  }}
                />

                <Tel
                  id="phone_number"
                  name="phone_number"
                  label={t("phone")}
                  isRequired={true}
                  register={register}
                  error={errors["phone_number"]}
                  placeholder={t("phone")}
                  validationSchema={{
                    required: t("forms.field_required", {
                      field: t("phone_number"),
                    }),
                    pattern: {
                      value: /^(9665|05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/i,
                      message: t("forms.wrong_pattern", {
                        field: t("phone_number"),
                        example: `(eg: 0555555555 / 966555555555)`,
                      }),
                    },
                  }}
                  control={control}
                />
                <button
                  variant="primary"
                  className="py-4 text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-[1rem] w-full text-base"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <span className="spinner-border spinner-border-sm">...</span>
                  ) : (
                    t("register.action")
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Signup;
