export const questions = [
  {
    id: 1,
    question: "كم مدة تنفيذ الطلب؟",
    answer:
      "بالنسبه للتطبيق بين 2-7 ايام تمر من خلاله بمراجعة فريق ويتر وابل وقوقل بلاي بالنسبه لاجهزة الكاشير 3-12 يوم حسب الضغط ومدينة العميل",
    isOpened: false,
  },
  {
    id: 2,
    question: "هل التطبيق ملك لي؟",
    answer:
      "نعم، التطبيق ملك لك وتتحكم به كما تريد، ويمكنك رفعه من حسابك او حساب ويتر على ابل وقوقل.",
    isOpened: false,
  },
  {
    id: 3,
    question: "لدي حساب مطور على ابل وقوقل هل استطيع رفعه تطبيقي عليه؟",
    answer: "لا، لكن قريباً سوف يتم تفعيل هذه الخاصية."
  },
  {
    id: 4,
    question: "هل التطبيق مربوط مع الكاشير؟",
    answer:
      "نعم، التطبيق والكاشير مربوطات في منصه واحده، ولكن هذا لايعني انه يجب شراءهم جميعًا، تستطيع شراء اجهزة الكاشير منفصله وبناء التطبيق منفصل وتفتح لك مزايا حسب طلبك",
    isOpened: false,
  },
  {
    id: 5,
    question: "هل الاشتراك يشمل التحديثات ؟",
    answer:
      "نعم، نعمل في فريق ويتر على تحسين التطبيقات دائمًا وكل ميزه مجانيه تضاف ستضاف للجميع بشكل تلقائي.",
    isOpened: false,
  },
  {
    id: 6,
    question: "هل التطبيق للمطاعم التي لديها توصيل فقط؟",
    answer:
      "لا، التطبيق لكل المطاعم والكافيهات واي نشاط تجاري يوصل او يوفر استلام داخل المطعم.",
    isOpened: false,
  },
  {
    id: 7,
    question: "هل يمكن اضافة اكثر من فرع في التطبيق؟",
    answer:
      "نعم، وعند الدخول من طرف العميل يتم اظهار الفروع مرتبه من المسافه الاقرب.",
    isOpened: false,
  },
  {
    id: 8,
    question: "هل يوجد رسوم من ويتر في خدمة التوصيل؟",
    answer:
      "لا، خدمة التوصيل اختياريه لصاحب المنشئة يمكنه اغلاقها او وضع مسافه حد اقصى لاكثر مسافه يتم التوصيل لها واضافة رسوم على التوصيل او مجانًا.",
    isOpened: false,
  },
  {
    id: 9,
    question: "هل الدفع الالكتروني يفعل تلقائي؟ وكيف تعرفون حساب التحويل؟",
    answer:
      "نعم يفعل مع التطبيق ودورة تحويل المدفوعات كل 3 ايام ، في صفحة بناء التطبيق نطلب منك حسابك البنكي لتفعيله في بوابة الدفع.",
    isOpened: false,
  },
  {
    id: 10,
    question: "هل ويتر لديه رسوم على الدفع الالكتروني؟",
    answer: "لا ويتر لاياخذ اي رسوم على الدفع الالكتروني، فقط رسوم بوابة الدفع الالكترونية وهي كالتالي: لكل عملية مدى 0.90% + 1 ريال رسوم إدارة الاحتيال، وفيزا/ماستركارد 2.7% لكل عملية.",
    isOpened: false,
  },
  {
    id: 11,
    question: "كيف تتم عملية توثيق التاجر في منصة ويتر؟",
    answer: "تتم عملية التوثيق والتصديق من خلال منصة واثق، حيث نطلب من التجار ادخال السجل التجاري ورقم هاتف المالك او رقم هاتف شخص لدية تفويض رسمي على السجل التجاري بعدها يتم ارسال رساله نصية تحتوي على رقم OTP ليتم تأكيد الهوية.",
    isOpened: false,
  }
];
