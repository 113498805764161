export const packages = [
  {
    id: 1,
    name: "باقة التطبيق",
    price: [
      { type: "شهريا", money: 399 },
      { type: "سنويا", money: 3990 },
    ],
    features: [
      "تطبيق على ابل ستور وقوقل بلاي",
      "تحكم لحظي ومباشر بالقوائم الخاصة بالتطبيق",
      "نظام نقاط الولاء (مع التحكم الكامل به)",
      "اضافة العروض وارسال الاشعارات",
      "الاستلام داخل الفرع",
      "امكانية تفعيل/تعطيل التوصيل للطلبات",
      "تحديد اقصى مسافة يمكن التوصيل لها",
      "ربط متكامل لتطبيق طلبات العملاء مع اجهزة كاشير ويتر",
      "الدفع الالكتروني مع التحكم الكامل فيه (تعطيل/تفعيل طريقة دفع معينة)",
      "اضافة اكثر من فرع",
      "بيانات عميلك لديك",
    ],
    special: false,
  },

  {
    id: 2,
    name: "باقة الكاشير",
    price: [
      { type: "شهريا", money: 99 },
      { type: "سنويا", money: 90 },
    ],
    features: [
      "ربط متكامل مع تطبيق العملاء",
      "اضافة وتحكم كامل بالمنتجات من الكاشير",
      "اضافة وتحكم كامل باكواد الخصم",
      "اضافة موظفين ولكل موظف حساب خاص به مع تحديد صلاحيات الموظفين",
      "جمع جميع الطابعات بالتطبيق (طباعة/تحميل فواتير للمطبخ او للعميل)",
      "استخدام اكثر من حساب للموظفين بنفس التطبيق والتنقل بينهم بسرعة وسلاسة",
      "فواتير لانهائية والعودة اي وقت للفواتير",
      "عدد طلبات لامحدود",
      "نظام الطاولات (حجز/الغاء الحجز)",
      "تطبيق سريع وسلس",
    ],
    special: false,
  },
  {
    id: 3,
    name: "الباقه المتكاملة 🌟",
    price: [
      { type: "مدى الحياة", money: 3399 },
      { type: "مدى الحياة", money: 3399 },
    ],
    features: [
      "جميع المميزات في باقة التطبيق + الكاشير",
      "يشمل جهاز ايباد ابل مع نظام ويتر كاشير مدى الحياة",
      "طابعتين",
      "صندوق نقود",
      "ستاند خاص لجاز الايباد",
    ],
    special: true,
  },
];
