import { Box, Stack, Typography, Grid } from "@mui/material";
import React from "react";
import Header from "../utils/header";
import SolContent from "../utils/solContent";

const ActivateIpad = () => {
  const step3Instructions = `
قم بالدخول إلى منصة ويتر عبر dashboard.waiter.sa ثم سجّل الدخول باستخدام الحساب الذي ترغب في تفعيل الجهاز عليه.
    
بعد تسجيل الدخول:
    • انتقل إلى قسم الاشتراكات ثم إلى اشتراك كاشير ويتر.
    • اضغط على زر عرض الأجهزة المرتبطة بالاشتراك.
    • في الصفحة التي تظهر، اضغط على إضافة جهاز جديد في الزاوية.
    • سيُطلب منك إدخال معرّف الجهاز الذي تم نسخه مسبقًا من تطبيق كاشير ويتر.
    • أدخل المعرّف، ثم اضغط على إضافة.
    
بعد إتمام هذه الخطوات، سيتم تفعيل تطبيق كاشير ويتر على الجهاز بنجاح، ويمكنك الآن تسجيل الدخول باستخدام حسابات الموظفين لديك.
    `;

  return (
    <Box>
      <Header
        text="طريقة تفعيل اشتراك كاشير ويتر"
        left={true}
        right={false}
      />

      <SolContent
        align="start"
        lgdir="row"
        title="الخطوة الاولى"
        head="تحميل تطبيق ويتر كاشير"
        video="step-1.MP4"
        padding="3.5rem 0 0 0"
        text="قم بتشغيل جهاز iPad الذي ترغب في تفعيله كجهاز كاشير Waiter، ثم انتقل إلى App Store، وابحث عن كاشير ويتر أو Waiter Casher، ثم قم بتنزيل التطبيق وتثبيته."
        width="90%"
        gap="9.3rem"
      />

      <SolContent
        align="start"
        lgdir="row"
        title="الخطوة الثانية"
        head="نسخ معرف الجهاز"
        video="step-2.MP4"
        padding="3.5rem 0 0 0"
        width="90%"
        gap="9.3rem"
        text="قم بفتح تطبيق كاشير ويتر، ثم اضغط على الزر الموجود في الزاوية السفلية اليمنى أو اليسرى، وذلك حسب لغة جهازك. ستظهر لك رسالة تحتوي على معرّف الجهاز، قم بنسخه وحفظه للاستخدام لاحقًا."
      />

      <SolContent
        align="start"
        lgdir="row"
        title="الخطوة الاخيرة"
        head="تفعيل جهاز الايباد في منصة وتر"
        video="step-3.MP4"
        padding="3.5rem 0 0 0"
        width="90%"
        gap="9.3rem"
        text={<pre className="whitespace-pre-wrap">{step3Instructions}</pre>}
      />
    </Box>
  );
};

export default ActivateIpad;
