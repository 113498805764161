import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://b55a318a507c5548e69bb3e3f458d3d0@o4506633289596928.ingest.us.sentry.io/4508801933115392",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

TagManager.initialize({ gtmId: "GTM-NLQK6K8F" });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={() => null}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
