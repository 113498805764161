import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import Header from "../utils/header";
import { packages } from "../data/packagesData";
import CustomButton from "../utils/customButton";
import { icons } from "../utils/icons";

const PackagesContent = ({ pack, selectedType }) => {
  const [filterFeatures, setFilterFeatures] = useState(true);
  return (
    <Grid
      item
      xs={12}
      md={12}
      lg={4}
      key={pack.id}
    >
      <Box
        sx={{
          borderRadius: "2rem",
          border: `2px solid ${
            pack.special ? "var(--blue)" : "var(--very-light-gray)"
          }`,
          padding: "2rem 2rem 3rem 2rem",
          transform: {
            md: "translateY(0)",
          },
          overflow: "hidden",
          position: pack.special ? "relative" : "unset",
          minHeight: { lg: "640px" },
        }}
      >
        {pack.special && (
          <Box
            sx={{
              position: "absolute",
              top: "15px",
              right: "-70px",
              transform: "rotate(35deg)",
              textAlign: "center",
              padding: "0.75rem 0",
              color: "white",
              backgroundColor: "var(--blue)",
              width: "250px",
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: "500" }}>
              موصى به
            </Typography>
          </Box>
        )}
        {pack.price[selectedType].type === "سنويا" && (
          <Box
            sx={{
              position: "absolute",
              top: "15px",
              right: "-70px",
              transform: "rotate(35deg)",
              textAlign: "center",
              padding: "0.75rem 0",
              color: "white",
              backgroundColor: "var(--blue)",
              width: "250px",
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: "500" }}>
              وفر شهرين
            </Typography>
          </Box>
        )}
        <Stack
          sx={{
            alignItems: "center",
            paddingBottom: "1.5rem",
            borderBottom: "1px solid var(--very-light-gray)",
          }}
        >
          <Stack sx={{ alignItems: "center", marginBottom: "1rem" }}>
            <Typography
              variant="h4"
              sx={{ fontSize: "1rem", color: "var(--gray)" }}
            >
              {pack.name}
            </Typography>
            {pack.id === 3 && (
              <Typography
                variant="p"
                sx={{ fontSize: "0.9rem", color: "var(--gray)" }}
              >
                كاشير وطابعه وصندوق نقود عرض
              </Typography>
            )}
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
                gap: "0.5rem",
                color: "var(--dark-gray)",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "3.5rem", fontWeight: "600" }}
              >
                {pack.price[selectedType].money}
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: "1.5rem", fontWeight: "500" }}
              >
                ر.س / {pack.price[selectedType].type}
              </Typography>
            </Stack>
            <Typography
              variant="body1"
              sx={{ fontWeight: "500", color: "var(--gray)" }}
            >
              يمكنك الإلغاء متى شئت
            </Typography>
          </Stack>

          {/* <CustomButton
            text="إبدأ الآن"
            bgcolor="var(--blue)"
            fs="1rem"
            textcolor="white"
            paddingsize="0.75rem 0"
            width="100%"
          /> */}
        </Stack>
        <Stack
          sx={{
            paddingTop: "1.5rem",

            gap: "1.5rem",
          }}
        >
          {filterFeatures
            ? pack.features
                .filter((_, i) => i <= 4)
                .map((feature, i) => (
                  <Stack
                    key={i}
                    direction="row"
                    sx={{ alignItems: "center", gap: "1rem" }}
                  >
                    <Box component="span">{icons.smallStar}</Box>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "500", color: "var(--gray)" }}
                    >
                      {feature}
                    </Typography>
                  </Stack>
                ))
            : pack.features.map((feature, i) => (
                <Stack
                  key={i}
                  direction="row"
                  sx={{ alignItems: "center", gap: "1rem" }}
                >
                  <Box component="span">{icons.smallStar}</Box>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "500", color: "var(--gray)" }}
                  >
                    {feature}
                  </Typography>
                </Stack>
              ))}
        </Stack>
        {pack.id === 3 ? null : (
          <Stack className="mt-4 m-3 ">
            <CustomButton
              style={{ marginTop: "24px", border: "none" }}
              onClick={() => setFilterFeatures(!filterFeatures)}
              text={filterFeatures ? "إظهار المزيد" : "تفاصيل أقل"}
              bgcolor="transperent"
              bor
              fs="0.75rem"
              textcolor="var(--blue)"
              paddingsize="0.25rem 0"
              width="100%"
            />
          </Stack>
        )}
      </Box>
    </Grid>
  );
};

const Packages = () => {
  const [switchPos, setSwitchPos] = useState("translateX(-95%)");
  const [selectedType, setSelectedType] = useState(0);

  const handleSwitch = () => {
    if (switchPos === "translateX(0%)") {
      setSwitchPos("translateX(-95%)");
      setSelectedType(0);
    } else {
      setSwitchPos("translateX(0%)");
      setSelectedType(1);
    }
  };
  return (
    <Stack
      id="prices"
      className="container set-padding"
      sx={{ gap: "2.25rem" }}
    >
      <Header
        text="إختر باقتك الآن."
        title="أسعارنا"
        left={true}
        right={false}
      />
      <Stack sx={{ alignItems: "center", marginBottom: "4rem" }}>
        <Stack
          direction="row"
          sx={{ gap: "0.75rem", alignItems: "center", color: "var(--black)" }}
        >
          <Typography variant="body1" fontSize="0.875rem">
            سنويا
          </Typography>
          <Box
            onClick={handleSwitch}
            sx={{
              width: "56px",
              border: "1px solid var(--blue)",
              borderRadius: "2rem",
              padding: "4px",
              cursor: "pointer",
            }}
          >
            <Box
              component="span"
              sx={{
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                backgroundColor: `${
                  selectedType === 0 ? "var(--black)" : "var(--blue)"
                }`,
                display: "block",
                transform: switchPos,
                transition: "all 0.2s ease-in",
              }}
            ></Box>
          </Box>
          <Typography variant="body1" fontSize="0.875rem">
            شهريا
          </Typography>
        </Stack>
      </Stack>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 0, lg: 2 }}
        width="100%"
      >
        {packages.map((pack, i) => (
          <PackagesContent pack={pack} selectedType={selectedType} />
        ))}
      </Grid>
    </Stack>
  );
};

export default Packages;
