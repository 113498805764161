import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import "./App.css";
import RootLayout from "./pages/rootLayout";
import Home from "./components/home";
import Solutions from "./components/solutions";
import Order from "./components/order";
import Return from "./components/Return";
import Signup from "./components/signup";
import ThankYouPage from "./components/thankyou";
import ActivateIpad from "./components/activate-ipad";
import "./i18n";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path="/solutions" element={<Solutions />} />
      <Route path="/order" element={<Order />} />
      <Route path="/return_and_exchange" element={<Return />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/thankyou" element={<ThankYouPage />} />
      <Route path="/activate-waiter-casher" element={<ActivateIpad />}/>
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;